import '@mrhenry/wp--bugsnag-config';
import '@mrhenry/wp--autoplay-in-view';

import './modules/cursor-thumbnail';
import './modules/navigation-overlay';
import './modules/video';

import { MrAccordion,
	MrInputSink,
	MrMuteToggle,
	MrPlayPauseToggle } from '@mrhenry/wp--mr-interactive';

customElements.define( 'mr-accordion', MrAccordion );
customElements.define( 'mr-input-sink', MrInputSink );
customElements.define( 'mr-mute-toggle', MrMuteToggle );
customElements.define( 'mr-play-pause-toggle', MrPlayPauseToggle );
