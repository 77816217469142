import { MrModalDialog } from '@mrhenry/wp--mr-interactive';

class MrNavigationOverlay extends MrModalDialog {
	override async willOpen() {
		await super.willOpen();
		document.body.classList.add( 'is-showing-modal-dialog' );
	}

	override async willClose() {
		await super.willClose();
		document.body.classList.remove( 'is-showing-modal-dialog' );
	}

	override openAnimations() {
		if ( !( 'KeyframeEffect' in window ) ) {
			return [];
		}

		return [
			new KeyframeEffect(
				document.querySelector( '.site-header__overlay-navigation__button .icon' ),
				[
					{
						transform: 'rotate(0deg)',
					},
					{
						transform: 'rotate(45deg)',
					},
				],
				{
					duration: 256,
					easing: 'ease-in',
					fill: 'forwards',
				}
			),
			new KeyframeEffect(
				this.querySelector( '.navigation-overlay__close__button .icon' ),
				[
					{
						transform: 'rotate(0deg)',
					},
					{
						transform: 'rotate(45deg)',
					},
				],
				{
					duration: 256,
					easing: 'ease-in',
					fill: 'forwards',
				}
			),
			new KeyframeEffect(
				this,
				[
					{
						opacity: '0',
					},
					{
						opacity: '1',
					},
				],
				{
					duration: 128,
					easing: 'ease-in',
					fill: 'forwards',
				}
			),
			new KeyframeEffect(
				this,
				[
					{
						clipPath: 'inset(0 0 calc(100% - 2.5rem) 0)',
					},
					{
						clipPath: 'inset(0 0 0 0)',
					},
				],
				{
					duration: 256,
					easing: 'ease-in',
					fill: 'forwards',
				}
			),
		];
	}

	override closeAnimations() {
		if ( !( 'KeyframeEffect' in window ) ) {
			return [];
		}

		return [
			new KeyframeEffect(
				document.querySelector( '.site-header__overlay-navigation__button .icon' ),
				[
					{
						transform: 'rotate(45deg)',
					},
					{
						transform: 'rotate(0deg)',
					},
				],
				{
					duration: 256,
					easing: 'ease-in',
					fill: 'forwards',
				}
			),
			new KeyframeEffect(
				this.querySelector( '.navigation-overlay__close__button .icon' ),
				[
					{
						transform: 'rotate(45deg)',
					},
					{
						transform: 'rotate(0deg)',
					},
				],
				{
					duration: 256,
					easing: 'ease-in',
					fill: 'forwards',
				}
			),
			new KeyframeEffect(
				this,
				[
					{
						opacity: '1',
					},
					{
						opacity: '0',
					},
				],
				{
					delay: 128,
					duration: 128,
					easing: 'ease-in',
					fill: 'forwards',
				}
			),
			new KeyframeEffect(
				this,
				[
					{
						clipPath: 'inset(0 0 0 0)',
					},
					{
						clipPath: 'inset(0 0 calc(100% - 2.5rem) 0)',
					},
				],
				{
					duration: 256,
					easing: 'ease-in',
					fill: 'forwards',
				}
			),
		];
	}
}

customElements.define( 'mr-navigation-overlay', MrNavigationOverlay );
