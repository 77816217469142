class MrCursorThumbnail extends HTMLElement {
	#cursorHandler = ( e: MouseEvent ) => {
		this.style.setProperty( '--cursor-thumbnail--left', `${e.clientX}px` );
		this.style.setProperty( '--cursor-thumbnail--top', `${e.clientY}px` );
	};

	#cursorHandlerOnce = () => {
		this.setAttribute( 'data-show', '' );
	};

	connectedCallback() {
		if ( window.matchMedia( '(prefers-reduced-motion: reduce), not all and (hover)' ).matches ) {
			return;
		}

		this.addEventListener( 'mousemove', this.#cursorHandler, {
			passive: true,
		} );

		this.addEventListener( 'mousemove', this.#cursorHandlerOnce, {
			passive: true,
			once: true,
		} );

		requestAnimationFrame( () => {
			Array.from( this.querySelectorAll( '[loading=lazy]' ) ).forEach( ( el ) => {
				el.removeAttribute( 'loading' );
			} );
		} );
	}

	disconnectedCallback() {
		this.removeEventListener( 'mousemove', this.#cursorHandler );
		this.removeEventListener( 'mousemove', this.#cursorHandlerOnce );
	}
}

window.customElements.define( 'mr-cursor-thumbnail', MrCursorThumbnail );
